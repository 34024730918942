import React, { useState, useRef } from 'react';
import { Switch } from 'antd';
import { AgGridColumn } from '@ag-grid-community/react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import Card from '../../common/components/Card';
import DataGrid from '../../common/components/DataGrid';

const AllUsersReport = ({ data }) => {
  const [employeesOnly, setEmployeesOnly] = useState(true);
  const exportRef = useRef();

  const headers = [
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Employee Type', key: 'employeeType' },
    { label: 'Email', key: 'email' },
    { label: 'Project Managers', key: 'pms' },
    { label: 'Approvers', key: 'approvers' },
    { label: 'Projects', key: 'projects' },
    { label: 'Last Active On', key: 'lastActiveOn' },
  ];

  const visibleRows = data.filter(user => {
    if (employeesOnly) {
      return user?.employeeType === 'W2';
    } else {
      return true;
    }
  }) || [];

  const tableData = visibleRows.map(item => {
    let pms;

    if (item.approvers?.map(a => a.value).includes('Project Manager')) {
      pms = item.projects
        ?.reduce((acc, cur) => {
          if (cur.projectManagers.length === 0) {
            return acc;
          }
          //if PM with same name but in different case and contains extra spaces
          const pms = cur.projectManagers.map(pm => pm.name?.toLowerCase().replace(/ +/g,',').replace(/,/g,' '));
          return [...new Set([...acc, ...pms])]; // remove dupes
        }, [])
        .join(', ');
    }

    const projects = item.projects?.map(project => project.name).join(', ');

    return {
      lastName: item.lastName,
      firstName: item.firstName,
      approvers: item.approvers?.reduce((acc, cur) => {
        if (cur.type === 'self') {
          return 'self';
        } else {
          return acc === '' ? cur.value : `${acc}, ${cur.value}`;
        }
      }, ''),
      pms,
      projects,
      employeeType: item.employeeType,
      email: item.emails[0].address,
      lastActiveOn: item.lastActiveOn ? moment(new Date(item.lastActiveOn)).format('LLL') : null,
    };
  });

  return (
    <Card
      padded={false}
      floating
      title="All Users"
      bodyBackground="none"
      actionComponent={
        <div className="flex">
          <div className="mx-2">
            <Switch
              defaultChecked
              onChange={checked => {
                setEmployeesOnly(checked);
              }}
            />
            <div>Employees Only</div>
          </div>

          <CSVLink
            headers={headers}
            filename={'Human_Resource_All_Users_' + Date.now() + '.csv'}
            data={tableData}
            className="btn"
          >
            Export CSV
          </CSVLink>
        </div>
      }
    >
      <DataGrid
        rowData={tableData}
        height="600px"
        exportFileName={'Human_Resource_All_Users_' + moment().format('YYYYMMDD-HHmm')}
        onExportReady={({ getExportFunc }) => {
          exportRef.current = getExportFunc;
        }}
      >
        <AgGridColumn field="firstName" />
        <AgGridColumn field="lastName" />
        <AgGridColumn field="employeeType" />
        <AgGridColumn field="email" />
        <AgGridColumn field="pms" headerName="Project Managers" />
        <AgGridColumn field="approvers" />
        <AgGridColumn field="projects" />
        <AgGridColumn field="lastActiveOn" headerName="Last Active On" />
      </DataGrid>
    </Card>
  );
};

export default AllUsersReport;
