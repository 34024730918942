import React from 'react';
import styled from 'styled-components';
import { AgGridColumn } from '@ag-grid-community/react';
import Card from '../../common/components/Card';
import Modal from '../../common/components/Modal';
import EditConfigForm from './EditConfigForm';
import { Box } from '@mantine/core';
import DataGrid from '../../common/components/DataGrid';

const Wrapper = styled.div`
  .edit_btn {
    color: #1890ff;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;

function capitalizeAndRemoveDashes(inputString) {
  // Split the string into words using dashes as separators
  let words = inputString.split('-');

  // Capitalize the first letter of each word
  let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words back together without dashes
  let result = capitalizedWords.join(' ');

  return result;
}

class ConfigurationList extends React.Component {
  state = {
    isModalVisible: false,
    formData: null,
  };

  render() {
    const { data, loading, handleUpdate } = this.props;
    const { isModalVisible, formData } = this.state;

    return (
      <Card
        border
        floating
        padded={false}
        title="Configurations"
        loading={loading}
        actionComponent={
          <Modal
            width={800}
            visible={isModalVisible}
            footer={null}
            onCancel={() => this.setState({ isModalVisible: false })}
          >
            <Box pt={4}>
              {isModalVisible && (
                <EditConfigForm
                  onComplete={() => {
                    this.setState({ isModalVisible: false });
                    handleUpdate();
                  }}
                  values={formData}
                />
              )}
            </Box>
          </Modal>
        }
      >
        <Wrapper>
          <DataGrid
            rowData={data}
            height="600px"
          >
            <AgGridColumn
              field="key"
              width={250}
              cellRendererFramework={({ data }) => <p>{capitalizeAndRemoveDashes(data.key)}</p>}
            />
            <AgGridColumn field="value" width={250} />
            <AgGridColumn field="description" width={800} />
            <AgGridColumn
              field="Actions"
              cellRendererFramework={({ data }) => (
                <p
                  className="edit_btn"
                  onClick={() => this.setState({ formData: data, isModalVisible: true })}
                >
                  Edit
                </p>
              )}
            />
          </DataGrid>
        </Wrapper>
      </Card>
    );
  }
}

export default ConfigurationList;
