import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { withRouter } from 'react-router-dom';
import { AgGridColumn } from '@ag-grid-community/react';
import DataGrid from '../../common/components/DataGrid';

class ProjectsReportGrid extends React.Component {
  render() {
    const { rowData = [], onExportReady } = this.props;
    return (
      <DataGrid
        rowData={rowData}
        onExportReady={onExportReady}
        exportFileName={`Projects-ExportedOn(${moment(new Date()).format('YYYYMMDD-hhmm')}).csv`}
      >
        <AgGridColumn field="projectCode" />
        <AgGridColumn field="client.name" />
        <AgGridColumn field="name" />
        <AgGridColumn
          field="budget"
          valueFormatter={({ colDef, data }) => {
            const budget = data[colDef.field];
            if (budget) {
              return numeral(budget).format('$0,0.[00]');
            }
          }}
        />
        <AgGridColumn
          field="totalCost"
          valueFormatter={({ colDef, data }) => {
            const budget = data[colDef.field];
            if (budget) {
              return numeral(budget).format('$0,0.[00]');
            }
          }}
        />
        <AgGridColumn
          field="budgetRemaining"
          valueFormatter={({ colDef, data }) => {
            const budget = data[colDef.field];
            if (budget) {
              return numeral(budget).format('$0,0.[00]');
            }
          }}
        />
        <AgGridColumn
          field="totalBilledAmount"
          valueFormatter={({ colDef, data }) => {
            const budget = data[colDef.field];
            if (budget) {
              return numeral(budget).format('$0,0.[00]');
            }
          }}
        />
        <AgGridColumn field="owner" />
        <AgGridColumn field="status" />
        <AgGridColumn field="sectors" />
        <AgGridColumn field="overBudget" />
        <AgGridColumn
          field="budgetUsed"
          headerName="Budget Used %"
          valueFormatter={({ colDef, data }) => {
            const budgetUsed = data[colDef.field];
            if (budgetUsed) {
              return numeral(budgetUsed).format('%0,0.00');
            }
          }}
        />
        <AgGridColumn field="archived" />
      </DataGrid>
    );
  }
}

export default withRouter(ProjectsReportGrid);
