import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { AgGridColumn } from '@ag-grid-community/react';
import DataGrid from '../../common/components/DataGrid';

class TimeOffReportGrid extends React.Component {
  render() {
    const { rowData = [], endDate, onExportReady } = this.props;
    return (
      <DataGrid
        rowData={rowData}
        onExportReady={onExportReady}
        exportFileName={`TimeOffReport-WeekEnding(${endDate})-ExportedOn(${moment(new Date()).format('YYYYMMDD-hhmm')}).csv`}
      >
        <AgGridColumn field="endDate" headerName="Week Ending" width={125} />
        <AgGridColumn field="status" width={175} />
        <AgGridColumn field="name" />
        <AgGridColumn field="email" />
        <AgGridColumn field="milestone" />
        <AgGridColumn field="deliverable" />
        <AgGridColumn field="taskdate" width={110} />
        <AgGridColumn field="hours" width={110} />
        <AgGridColumn field="comment" />
      </DataGrid>
    );
  }
}

export default withRouter(TimeOffReportGrid);
