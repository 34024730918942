import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { AgGridColumn } from '@ag-grid-community/react';
import DataGrid from '../../common/components/DataGrid';

class ProjectFinancialsDetailsGrid extends React.Component {
  render() {
    const { rowData, endDate, onExportReady } = this.props;
    return (
      <DataGrid
        rowData={rowData}
        onExportReady={onExportReady}
        exportFileName={`ProjectFinancials-(${endDate})-ExportedOn(${moment(new Date()).format('YYYYMMDD-hhmm')}).csv`}
      >
        <AgGridColumn field="endDate" headerName="Timesheet Ending Date" width={125} />
        <AgGridColumn field="status" width={175} />
        <AgGridColumn field="lastName" />
        <AgGridColumn field="firstName" />
        <AgGridColumn field="role" />
        <AgGridColumn field="email" />
        <AgGridColumn field="clientName" width={150} />
        <AgGridColumn field="project" />
        <AgGridColumn field="milestone" />
        <AgGridColumn field="deliverable" />
        <AgGridColumn field="taskDate" width={110} />
        <AgGridColumn field="hours" width={110} />
        <AgGridColumn field="totalCost" width={110} />
        <AgGridColumn field="comment" />
      </DataGrid>
    );
  }
}

export default withRouter(ProjectFinancialsDetailsGrid);
