import React from 'react';
import styled from 'styled-components';
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { getGlobal } from 'reactn';
import gql from 'graphql-tag';
import client from '../../../api/graphql/client';

import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

const UPDATE_USER_SETTINGS = gql`
  mutation UpdateUserSettings($id: ID!, $input: UpdateUserSettingsInput!) {
    updateUserSettings(_id: $id, input: $input)
  }
`;

const Wrapper = styled.div`
  height: ${props => props.height || '700px'};
  width: 100%;
  div.ag-root {
    border: 0;
  }
  div.ag-row:last-child {
    border-bottom: 0;
  }
`;

class DataGrid extends React.Component {
  gridApi = undefined;
  gridColumnApi = undefined;
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    // Ensure grid is properly destroyed
    if (this.gridApi) {
      this.gridApi.destroy();
      this.gridApi = null;
    }
    if (this.gridColumnApi) {
      this.gridColumnApi = null;
    }
  }

  getStorageKey = () => {
    const { location } = this.props;
    // Normalize project management paths to ignore specific project IDs
    const normalizedPath = location.pathname.replace(
      /^(\/project-management\/projects\/).*$/,
      '$1'
    );
    return `ag-grid-columns-${normalizedPath}`;
  };

  saveColumnState = async () => {
    if (this._isMounted && this.gridColumnApi) {
      try {
        const columnState = this.gridColumnApi.getColumnState();
        const { user } = getGlobal();
        
        // Save to database
        await client.mutate({
          mutation: UPDATE_USER_SETTINGS,
          variables: {
            id: user._id,
            input: {
              agGridColumns: JSON.stringify({
                [this.getStorageKey()]: columnState
              })
            }
          }
        });
      } catch (error) {
        console.warn('Failed to save column state:', error);
        // Fallback to localStorage
        try {
          const columnState = this.gridColumnApi.getColumnState();
          localStorage.setItem(this.getStorageKey(), JSON.stringify(columnState));
        } catch (localError) {
          console.warn('Failed to save column state to localStorage:', localError);
        }
      }
    }
  };

  loadColumnState = () => {
    if (!this._isMounted) return;

    try {
      const { user } = getGlobal();
      const savedState = user?.settings?.agGridColumns;
      
      if (savedState) {
        const allGridStates = JSON.parse(savedState);
        const columnState = allGridStates[this.getStorageKey()];
        
        if (columnState && this.gridColumnApi) {
          this.gridColumnApi.setColumnState(columnState);
          return;
        }
      }

      // Fallback to localStorage
      const localState = localStorage.getItem(this.getStorageKey());
      if (localState && this.gridColumnApi) {
        const columnState = JSON.parse(localState);
        this.gridColumnApi.setColumnState(columnState);
      }
    } catch (error) {
      console.warn('Failed to load column state:', error);
    }
  };

  onGridReady = params => {
    if (!this._isMounted) return;

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    
    const { onExportReady } = this.props;
    
    if (onExportReady) {
      onExportReady({ getExportFunc: () => this.export });
    }

    // Load saved column state for this URL path
    // Use setTimeout to ensure the grid is fully initialized
    setTimeout(() => {
      if (this._isMounted) {
        this.loadColumnState();
      }
    }, 0);
  };

  export = () => {
    if (!this._isMounted || !this.gridApi) return;

    const { exportFileName } = this.props;
    const defaultFileName = `Export-${moment(new Date()).format('YYYYMMDD-hhmm')}.csv`;
    
    const params = {
      fileName: exportFileName || defaultFileName,
    };
    this.gridApi.exportDataAsCsv(params);
  };

  render() {
    const {
      rowData,
      children,
      height,
      className = 'ag-theme-balham',
      defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
      },
      ...restProps
    } = this.props;

    return (
      <Wrapper height={height} className={className}>
        <AgGridReact
          key={this.props.location.pathname} // Force recreation on route change
          modules={AllCommunityModules}
          onGridReady={this.onGridReady}
          onColumnMoved={this.saveColumnState}
          onColumnPinned={this.saveColumnState}
          onColumnVisible={this.saveColumnState}
          enableCellTextSelection
          rowData={rowData}
          defaultColDef={defaultColDef}
          suppressPropertyNamesCheck={true} // Prevent bean validation errors
          {...restProps}
        >
          {children}
        </AgGridReact>
      </Wrapper>
    );
  }
}

export default withRouter(DataGrid); 