import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { AgGridColumn } from '@ag-grid-community/react';
import DataGrid from '../../common/components/DataGrid';

class MissingTimesheetsReportGrid extends React.Component {
  render() {
    const { rowData = [], endDate, onExportReady } = this.props;
    return (
      <DataGrid
        rowData={rowData}
        onExportReady={onExportReady}
        exportFileName={`MissingTimesheets-WeekEnding(${endDate})-ExportedOn(${moment(new Date()).format('YYYYMMDD-hhmm')}).csv`}
      >
        <AgGridColumn width={400} field="projectName" />
        <AgGridColumn width={200} field="resourceName" />
        <AgGridColumn width={200} field="resourceEmail" />
        <AgGridColumn field="endDate" />
      </DataGrid>
    );
  }
}

export default withRouter(MissingTimesheetsReportGrid);
