import React from 'react';
import { AgGridColumn } from '@ag-grid-community/react';
import { Select } from 'antd';
import DataGrid from '../../common/components/DataGrid';

const Filters = ({ rowData, onProjectSelect, onUserSelect }) => {
  const projects = rowData.reduce((acc, cur) => {
    if (!acc.includes(cur.project)) {
      return [...acc, cur.project];
    }
    return acc;
  }, []);
  const users = rowData.reduce((acc, cur) => {
    if (!acc.includes(cur.name)) {
      return [...acc, cur.name];
    }
    return acc;
  }, []);
  return (
    <div className="p-4 flex justify-end gap-2">
      <Select
        placeholder="Filter by project"
        notFoundContent="No data for given date range"
        className="w-1/5"
        onChange={onProjectSelect}
        allowClear
      >
        {projects.map(project => (
          <Select.Option key={project} value={project}>
            {project}
          </Select.Option>
        ))}
      </Select>
      <Select
        placeholder="Filter by employee"
        notFoundContent="No data for given date range"
        className="w-1/5"
        onChange={onUserSelect}
        allowClear
      >
        {users.map(user => (
          <Select.Option key={user} value={user}>
            {user}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

class TimesheetDailySnapshot extends React.Component {
  render() {
    const { rowData, onProjectSelect, onUserSelect } = this.props;

    return (
      <div>
        <Filters
          rowData={rowData}
          onProjectSelect={onProjectSelect}
          onUserSelect={onUserSelect}
        />
        <DataGrid
          rowData={rowData}
          height="600px"
        >
          <AgGridColumn field="name" />
          <AgGridColumn field="project" />
          <AgGridColumn field="deliverable" />
          <AgGridColumn field="milestone" />
          <AgGridColumn field="hours" />
        </DataGrid>
      </div>
    );
  }
}

export default TimesheetDailySnapshot;
