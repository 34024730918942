import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { AgGridColumn } from '@ag-grid-community/react';
import { getWeekDays } from '../../common';
import DataGrid from '../../common/components/DataGrid';

class TimesheetSnapshot extends React.Component {
  render() {
    const { rowData, endDate, startDate, onExportReady } = this.props;
    const days = getWeekDays();

    return (
      <DataGrid
        rowData={rowData}
        onExportReady={onExportReady}
        exportFileName={`TimesheetSnapshot-${startDate}_${endDate}-ExportedOn(${moment(new Date()).format('YYYYMMDD-hhmm')}).csv`}
      >
        <AgGridColumn headerName="Week Ending" field="endDate" sort='asc' width={125} />
        <AgGridColumn field="status" width={175} />
        <AgGridColumn field="lastName" />
        <AgGridColumn field="firstName" />
        <AgGridColumn field="email" />
        <AgGridColumn field="role" />
        <AgGridColumn field="clientName" width={150} />
        <AgGridColumn field="project" />
        <AgGridColumn field="milestone" />
        <AgGridColumn field="deliverable" />
        {days.map(day => (
          <AgGridColumn key={day} field={day} width={75} />
        ))}
        <AgGridColumn field="totalHours" width={75} />
        <AgGridColumn field="totalCost" width={100} />
        <AgGridColumn field="comment" />
      </DataGrid>
    );
  }
}

export default withRouter(TimesheetSnapshot);
