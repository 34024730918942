import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { AgGridColumn } from '@ag-grid-community/react';
import DataGrid from '../../common/components/DataGrid';

class PayrollAllocationReportGrid extends React.Component {
  render() {
    const { rowData = [], endDate, onExportReady } = this.props;
    return (
      <DataGrid
        rowData={rowData}
        onExportReady={onExportReady}
        exportFileName={`TimesheetSnapshot-WeekEnding(${endDate})-ExportedOn(${moment(new Date()).format('YYYYMMDD-hhmm')}).csv`}
      >
        <AgGridColumn field="lastName" />
        <AgGridColumn field="firstName" />
        <AgGridColumn field="payrollId" headerName="Payroll ID" />
        <AgGridColumn field="email" />
        <AgGridColumn field="project" />
        <AgGridColumn field="bu" />
        <AgGridColumn field="totalHours" />
        <AgGridColumn field="allocation" />
      </DataGrid>
    );
  }
}

export default withRouter(PayrollAllocationReportGrid);
