import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { AgGridColumn } from '@ag-grid-community/react';
import { getWeekDays } from '../../common';
import DataGrid from '../../common/components/DataGrid';

class TimesheetWeeklySnapshot extends React.Component {
  render() {
    const { rowData, endDate, onExportReady } = this.props;
    const days = getWeekDays();

    return (
      <DataGrid
        rowData={rowData}
        onExportReady={onExportReady}
        exportFileName={`TimesheetSnapshot-WeekEnding(${endDate})-ExportedOn(${moment(
          new Date()
        ).format('YYYYMMDD-hhmm')}).csv`}
      >
        <AgGridColumn field="endDate" width={125} />
        <AgGridColumn field="status" width={175} />
        <AgGridColumn field="lastName" />
        <AgGridColumn field="firstName" />
        <AgGridColumn field="payrollId" headerName="Payroll ID" />
        <AgGridColumn field="email" />
        <AgGridColumn field="employeeType" />
        <AgGridColumn field="workCalendar" />
        <AgGridColumn field="clientName" width={150} />
        <AgGridColumn field="project" />
        <AgGridColumn field="projectCode" />
        {/* <AgGridColumn field="bu" headerName="BU - IS" /> */}
        <AgGridColumn field="milestone" />
        <AgGridColumn field="deliverable" />
        {days.map(day => (
          <AgGridColumn key={day} field={day} width={75} />
        ))}
        <AgGridColumn field="total" width={75} />
        {/* <AgGridColumn field="comment" /> */}
      </DataGrid>
    );
  }
}

export default withRouter(TimesheetWeeklySnapshot);
